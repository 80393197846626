.movableheading {
  position: relative;
  overflow: hidden;
  text-align: center;
  width: 280px; /* Adjust the width as needed */
  height: 50px;
  padding-right: 5px; /* Adjust the height as needed */
}

.App {
  background-image: url(./BG.png);
  background-size: cover;
  min-height: 100vh;
  overflow: auto;
  overflow-x: hidden;
  background-attachment: fixed;
  background-position: top;
  background-repeat: no-repeat;

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


.header {
  padding-bottom: 10px;
  margin-bottom: 10px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.card {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  text-align: center;
  
  width: 300px;
  background-image: linear-gradient(to bottom right, rgb(99, 87, 87), rgb(243, 243, 241));
  background-color: #5d6870;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}



.cardcontent {
  padding: 16px;
}

.cardtitle {
  font-size: 20px;
  width: 300px;
  align-items: center;
  
  text-align: center;
  color: #5d6870;
  border-radius: 8px;
  margin-bottom: 20px;
 
}

.carddescription {
  font-size: 20px;
  align-items: flex-start;
  color: #555;
}

.searchbutton {
  padding: 8px;
  align-items: center;
  border-radius: 3px;
  width: 50px;
  margin-bottom: 20px;
}

input {
  padding: 8px;
  outline: none;
} 